import { useCrafters } from "../providers/directory/hooks/useCrafters";
import { useProfile } from "../providers/profile/hooks/useProfile";
import { ReactElement, useEffect, useState } from "react";
import SearchBar from "../components/directory/SearchBar";
import { useCv } from "../providers/profile/hooks/useCv";
import { IconListDetails } from "@tabler/icons-react";
import Crafter from "../components/directory/Crafter";
import FlexboxLayer from "../layers/FlexboxLayer";
import Loader from "../components/common/Loader";
import PageLayer from "../layers/PageLayer";
import { Profile } from "../types/profile";

const Directory = (): ReactElement => {
  const [filteredCrafters, setFilteredCrafters] = useState<Profile[]>([]);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currentProfileCvDownloadUrl = useCv();
  const currentProfile = useProfile();
  const craftersList = useCrafters();

  useEffect(() => {
    if (craftersList !== null) {
      setFilteredCrafters(craftersList);
      setProfile(currentProfile);
      setIsLoading(false);
    }
  }, [craftersList, currentProfile]);

  const handleSearchChange = (search: string): void => {
    let updatedFilteredCrafters: Profile[];

    if (search.length === 0) {
      updatedFilteredCrafters = craftersList ?? [];
    } else {
      const normalizedSearch = search.toLowerCase();

      updatedFilteredCrafters = craftersList?.filter((crafter: Profile) => {
        const { firstname, lastname } = crafter;
        return firstname?.toLowerCase().includes(normalizedSearch) || lastname?.toLowerCase().includes(normalizedSearch);
      }) ?? [];
    }

    setFilteredCrafters(updatedFilteredCrafters);
  };

  return (
    <>
      {isLoading && <Loader />}
      <PageLayer>
        <header>
          <div className="icon">
            <IconListDetails size={55} />
          </div>
          <div className="text">
            <h1 className="page-title">Annuaire</h1>
            <span className="crafters-count">
              {filteredCrafters.length} crafter{filteredCrafters.length > 1 ? 's' : ''}
            </span>
          </div>
        </header>
        <SearchBar onChange={handleSearchChange} />
        <FlexboxLayer>
          {filteredCrafters.map((crafter: Profile, index: number) => {
            const isCurrentProfile: boolean = crafter.email === profile?.email;
            return (
              <Crafter
                cvDownloadUrl={isCurrentProfile ? currentProfileCvDownloadUrl : undefined}
                isCurrentProfile={isCurrentProfile}
                crafter={isCurrentProfile ? profile! : crafter}
                key={index}
              />
            );
          })}
        </FlexboxLayer>
      </PageLayer>
    </>
  );
};

export default Directory;
