import tabs from "./static/others/navigationTabs.json";
import { Tab } from "./types/tab";

const tabsArray: Tab[] = Object.values(tabs);

export const isLocal: boolean = window.location.hostname === "localhost";
export const isDev: boolean = isLocal || window.location.hostname === "dev.my.socraft.ch";
export const isQa: boolean = window.location.hostname === "qa.my.socraft.ch";
export const isProd: boolean = window.location.hostname === "my.socraft.ch";

const productionTabs: string[] = ["Accueil", "Le QG", "Profil", "Annuaire", "Calendrier"];
const localTabs: string[] = ["Accueil", "Le QG", "Profil", "Annuaire", "Calendrier", "Comptes"];

export const displayedTabs: Tab[] = [];

if (isDev || isQa) {
  localTabs.forEach((tabName: string) => {
    const localTab: Tab | undefined = tabsArray
      .find((tab) => tab.name === tabName);
    displayedTabs.push(localTab!);
  });
} else {
  productionTabs.forEach((tabName: string) => {
    const productionTab: Tab | undefined = tabsArray
      .find((tab) => tab.name === tabName);
    displayedTabs.push(productionTab!);
  });
}
