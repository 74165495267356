import { formatHubspotContact } from "../../shared/hubspot/formatHubspotContact";
import { Permission, Profile, ProfileProviderProps } from "../../types/profile";
import { getPremiumValidity } from "../../shared/profile/getPremiumValidity";
import { createContext, useState, useEffect, ReactElement } from "react";
import { auth, db, functions, storage } from "../../firebase.config";
import { toTimestamp } from "../../shared/others/formatDate";
import { uploadFile } from "../../shared/others/uploadFile";
import { deleteFile } from "../../shared/others/deleteFile";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import links from "../../static/others/links.json";
import { log } from "../../shared/analytics/log";
import { isProd } from "../../environnment";
import sha256 from "sha256";

export const ProfileContext = createContext(undefined);

export const ProfileProvider = ({ children }: ProfileProviderProps): ReactElement => {
  const [cvFilePath, setCvFilePath] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<Permission[]>();
  const [profile, setProfile] = useState<Profile | null>(null);

  const authEmail: string | null = auth.currentUser!.email;

  useEffect(() => {
    const initProfile = async (): Promise<void> => {
      if (authEmail) {
        const profileDocumentReference = doc(db, "profiles", authEmail);

        try {
          const profileDocumentSnapshot = await getDoc(profileDocumentReference);

          if (profileDocumentSnapshot.exists()) {
            setProfile(profileDocumentSnapshot.data() as Profile);
          } else {
            const fetchHubspotContact = httpsCallable(functions, "fetchHubspotContact");
            const hubspotContact = await fetchHubspotContact(authEmail);

            let formattedProfile: Profile = formatHubspotContact(hubspotContact.data);
            const emailHash = sha256(formattedProfile.email);
            const defaultImageURL = links.defaultAvatar;

            formattedProfile.profilePicture = `https://www.gravatar.com/avatar/${emailHash}?s=200&d=${defaultImageURL}`;

            await setDoc(profileDocumentReference, formattedProfile);
            setProfile(formattedProfile);
          }
        } catch (error: any) {
          throw new Error(`while initializing profile : ${error}`);
        }
      }

      const cvFileReference = ref(storage, `CV/${authEmail}`);

      try {
        const value = await getDownloadURL(cvFileReference);
        setCvFilePath(value);
      } catch (error: any) {
        console.warn(`${authEmail}'s CV not found. The 404 error above this message is therefore not important`);
        setCvFilePath('');
      }
    };

    void initProfile();
  }, [authEmail]);

  useEffect(() => {
    const initPermissions = async (): Promise<void> => {
      if (profile) {
        try {
          const premiumValidity = await getPremiumValidity(profile);

          const getPermissions = httpsCallable(functions, "getPermissionsForUser");
          let permissions = (await getPermissions({ email: profile.email })).data as Permission[];

          if (premiumValidity.needToBeUpdatedInProfile) {
            const profileDocumentReference = doc(db, "profiles", profile.email);

            const updatedProfile: Profile = {
              ...profile,
              premiumUntil: premiumValidity.validUntil,
            };

            await setDoc(profileDocumentReference, updatedProfile);
          }

          const hasPremiumRole: boolean = permissions.some((permission) => {
            return permission.sources.some((source) => {
              return source.source_name === "Premium";
            });
          });

          if (!premiumValidity.isValid && hasPremiumRole) {
            const removeRoleFromUser = httpsCallable(functions, "removeRoleFromUser");

            const roleId = permissions.find((permission) => {
              return permission.sources.find(source => source.source_name === "Premium");
            })?.sources.find((source) => {
              return source.source_name === "Premium";
            })?.source_id;

            await removeRoleFromUser({ email: profile.email, roleToRemove: roleId });

            permissions = (await getPermissions({ email: profile.email })).data as Permission[]; 
          }

          setPermissions(permissions);
        } catch (error: any) {
          throw new Error(error);
        }
      }
    };

    void initPermissions();
  }, [profile]);

  const editProfile = async (updatedProfile: Profile): Promise<void> => {
    const jsonConfig = isProd
      ? require("../../static/profile/hubspotProdFields.json")
      : require("../../static/profile/hubspotQaFields.json");

    const updatedHubspotProperties: any[] = [];

    Object.entries(updatedProfile).forEach(([property, value]: [any, string | number]) => {
      if (value && typeof value !== "boolean") {
        const hubspotField = jsonConfig[property];

        if (property !== "technical_skills" && (!hubspotField || !String(value).length)) {
          return;
        }

        if (property === "birth_date") {
          value = toTimestamp(value as string);
        }

        const updatedHubspotProperty = {
          property: jsonConfig[property],
          value,
        };

        updatedHubspotProperties.push(updatedHubspotProperty);
      }
    });

    Object.keys(updatedProfile).forEach((key): void => {
      if (!updatedProfile[key as keyof Profile]) {
        delete updatedProfile[key as keyof Profile];
      }
    });

    const updateUser = httpsCallable(functions, "updateHubspotContact");

    try {
      await updateUser({
        userEmail: authEmail,
        editedProperties: updatedHubspotProperties,
      });

      log("Profile modified");
    } catch (error: any) {
      throw new Error(`while updating Hubspot contact : ${error}`);
    }

    const profileDocumentReference = doc(db, "profiles", authEmail!);

    try {
      await setDoc(profileDocumentReference, updatedProfile);
      setProfile(updatedProfile);
    } catch (error: any) {
      throw new Error(`while updating my.socraft profile : ${error}`);
    }
  };

  const uploadCv = async (cvFile: File): Promise<void> => {
    try {
      const cvDownloadUrl: string = await uploadFile("CV", profile?.email!, cvFile, true) as string;
      setCvFilePath(cvDownloadUrl);

      log("CV uploaded");
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteCv = async (): Promise<void> => {
    try {
      await deleteFile("CV", authEmail!);
      setCvFilePath('');

      log("CV deleted")
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const profileContextValue: any = {
    profile,
    permissions,
    cvFilePath,
    editProfile,
    uploadCv,
    deleteCv,
  };

  return (
    <ProfileContext.Provider value={profileContextValue}>
      {children}
    </ProfileContext.Provider>
  );
};
