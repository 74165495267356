import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase.config";

export const uploadFile = async (filePath: string, fileName: string, file: File, shouldReturnDownloadUrl: boolean): Promise<void | string> => {
  const fileReference = ref(storage, `${filePath}/${fileName}`);

  try {
    await uploadBytes(fileReference, file);

    if (shouldReturnDownloadUrl) {
      return await getDownloadURL(fileReference);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};
