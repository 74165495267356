import { SkeletonProps } from "../../types/skeleton";
import { ReactElement } from "react";

const Skeleton = ({ height, width }: SkeletonProps): ReactElement => {
  return (
    <div
      className="skeleton"
      style={{ width, height }}
    ></div>
  );
};

export default Skeleton;
