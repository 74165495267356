import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { isLocal, isQa, isProd } from "./environnment";
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import "@firebase/auth-compat";

let firebaseConfig = {
  apiKey: "AIzaSyANVOUZnnjRrGRwGY-UPnDvwq7apeWZzKg",
  authDomain: "mysocraft-dev.firebaseapp.com",
  projectId: "mysocraft-dev",
  storageBucket: "mysocraft-dev.appspot.com",
  messagingSenderId: "1096634320138",
  appId: "1:1096634320138:web:a39c45aba0acc5653ea6f3",
};

if (isQa) {
  firebaseConfig = {
    apiKey: "AIzaSyC4LAxXIJlanhpC90oHxRb4NEmQuv9tjzQ",
    authDomain: "qa.my.socraft.ch",
    projectId: "mysocraft-qa",
    storageBucket: "mysocraft-qa.appspot.com",
    messagingSenderId: "155789786896",
    appId: "1:155789786896:web:d84b371339f6a1593d2f95",
  };
}

if (isProd) {
  firebaseConfig = {
    apiKey: "AIzaSyD9X_yZpUahWFIDxO09wuWgKcIcWc8Jq_E",
    authDomain: "my.socraft.ch",
    projectId: "mysocraft-a456a",
    storageBucket: "mysocraft-a456a.appspot.com",
    messagingSenderId: "240493745662",
    appId: "1:240493745662:web:d97cc9736183db5f361caf",
  };
}

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west6");
export const storage = getStorage(firebaseApp);

if (isLocal) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

export default firebaseApp;
