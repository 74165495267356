import { Permission } from "../../../types/profile";
import { ProfileContext } from "../ProfileProvider";
import { useContext } from "react";

export const usePermissions = (): Permission[] => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("usePermissions must be used within a ProfileProvider");
  }

  return profileContext.permissions;
};
