import { DoorsContext } from "../DoorsProvider";
import { useContext } from "react";

export const useControlDoors = (): ((action: string) => Promise<boolean>) => {
  const doorsContext = useContext<any>(DoorsContext);

  if (!doorsContext) {
    throw new Error("useControlDoors must be used within a DoorsProvider");
  };

  return doorsContext.controlDoors;
};
