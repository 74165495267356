import { DirectoryContext } from "../DirectoryProvider";
import { Profile } from "../../../types/profile";
import { useContext } from "react";

export const useCrafters = (): Profile[] | null => {
  const directoryContext = useContext<any>(DirectoryContext);

  if (!directoryContext) {
    throw new Error("useCrafters must be used within a DirectoryProvider");
  }

  return directoryContext.crafters;
};
