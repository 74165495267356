import { ProfileContext } from "../ProfileProvider";
import { useContext } from "react";

export const useDeleteCv = (): (() => Promise<void>) => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useDeleteCv must be used within a ProfileProvider");
  }

  return profileContext.deleteCv;
};
