import { DirectoryContext } from "../DirectoryProvider";
import { useContext } from "react";

export const useCrafterCv = (): ((crafterEmail: string) => Promise<string | undefined>) => {
  const directoryContext = useContext<any>(DirectoryContext);

  if (!directoryContext) {
    throw new Error("useCrafterCv must be used within a DirectoryProvider");
  }

  return directoryContext.getCrafterCv;
};
