import { DirectoryContext } from "../DirectoryProvider";
import { useContext } from "react";

export const useBirthdays = (): string[] => {
  const directoryContext = useContext<any>(DirectoryContext);

  if (!directoryContext) {
    throw new Error("useBirthdays must be used within a DirectoryProvider");
  }

  return directoryContext.birthdays;
};
