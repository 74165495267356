import { ProfileContext } from "../ProfileProvider";
import { useContext } from "react";

export const useCv = (): string => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useCv must be used within a ProfileProvider");
  }

  return profileContext.cvFilePath;
};
