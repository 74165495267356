import { useState, useEffect, ReactElement } from "react";
import { DateTime } from "luxon";

const Date = (): ReactElement => {
  const [currentMoment, setCurrentMoment] = useState(
    DateTime.local().setLocale("fr").toFormat("cccc, HH:mm")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMoment(
        DateTime.local().setLocale("fr").toFormat("cccc, HH:mm")
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <span data-aos="fade" data-aos-delay="100" className="date">
      {currentMoment}
    </span>
  );

};

export default Date;
