import { ProfileContext } from "../ProfileProvider";
import { Profile } from "../../../types/profile";
import { useContext } from "react";

export const useProfile = (): Profile => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }

  return profileContext.profile;
};
