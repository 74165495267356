import { CardProps } from "../../types/card";
import { ReactElement } from "react";

const Card = ({ hasHeader, headerTitle, children, hasFooter, footerContent, customClass, aosDelay }: CardProps): ReactElement => {
  return (
    <div data-aos="fade-up" data-aos-delay={aosDelay} className={`card ${customClass}`}>
      {hasHeader && (
        <header>
          {headerTitle}
        </header>
      )}
      <div className="card-body">
        {children}
      </div>
      {hasFooter && (
        <footer>
          {footerContent}
        </footer>
      )}
    </div>
  );
};

export default Card;
