import { createContext, useState, useEffect, ReactElement } from "react";
import { getIndexOfStatus } from "../../shared/doors/getIndexOfStatus";
import { Door, DoorsProviderProps } from "../../types/doors";
import doorsData from "../../static/doors/doors.json";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase.config";

export const DoorsContext = createContext(undefined);

export const DoorsProvider = ({ children }: DoorsProviderProps): ReactElement => {
  const [doors, setDoors] = useState<Door[]>();

  useEffect(() => {
    const initDoors = async (): Promise<void> => {
      const getDoorStatus = httpsCallable(functions, "getDoorStatus");
      const doorsArray: Door[] = [];

      for (const door of doorsData) {
        try {
          const doorId: number = door.id;
          const doorStatus = await getDoorStatus({ doorId });

          const doorData: Door = {
            ...door,
            state: doorStatus.data,
          };

          doorsArray.push(doorData);
        } catch (error: any) {
          throw new Error(error);
        }
      };

      setDoors(doorsArray);
    };

    void initDoors();
  }, []);

  const controlDoors = async (requestedAction: "lock" | "unlock"): Promise<boolean> => {
    return new Promise<boolean>(async (resolve) => {
      const callDoor = httpsCallable(functions, "callNukiDoor");
      const getDoorStatus = httpsCallable(functions, "getDoorStatus");
      
      const expectedActionIndex: number = getIndexOfStatus(requestedAction);
      const resultActionsIndexes: number[] = [];
      const updatedDoors: Door[] = [];
  
      await Promise.all([
        callDoor({ action: requestedAction, doorId: doorsData[0].id }),
        callDoor({ action: requestedAction, doorId: doorsData[1].id }),
      ]);
  
      setTimeout(async () => {
        for (const door of doorsData) {
          const doorStatus = await getDoorStatus({ doorId: door.id }) as any;
          resultActionsIndexes.push(doorStatus.data.state);

          const updatedDoor: Door = {
            ...door,
            state: doorStatus.data,
          };
          updatedDoors.push(updatedDoor);
        }

        setDoors(updatedDoors);
        const result = resultActionsIndexes.every((index) => index === expectedActionIndex);
        resolve(result);
      }, 8000);
    });
  };

  const value: any = {
    doors,
    controlDoors,
  };

  return (
    <DoorsContext.Provider value={value}>
      {children}
    </DoorsContext.Provider>
  );
};
