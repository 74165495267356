import { ProfileContext } from "../ProfileProvider";
import { useContext } from "react";

export const useUploadCv = (): ((cvFile: File) => Promise<void>) => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useUploadCv must be used within a ProfileProvider");
  }

  return profileContext.uploadCv;
};
