import technicalSkills from "../../static/profile/technicalSkills.json";
import { TechnicalSkillsSelectorProps } from "../../types/profile";
import { ReactElement, useEffect, useState } from "react";
import { IconX } from "@tabler/icons-react";
import Swal from "sweetalert2";

const TechnicalSkillsPopup = ({ alreadySelectedTechnicalSkills, onUpdate }: TechnicalSkillsSelectorProps): ReactElement => {
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  useEffect(() => {
    if (!alreadySelectedTechnicalSkills.some((skill: string): boolean => !skill.length)) {
      setSelectedSkills(alreadySelectedTechnicalSkills);
    }
  }, [alreadySelectedTechnicalSkills]);

  const handleCheckChange = (skill: string): void => {
    const updatedSelectedSkills: string[] = [...selectedSkills];

    const skillIndex = selectedSkills.findIndex((s: string): boolean => {
      return s === skill;
    });

    if (skillIndex === -1) {
      updatedSelectedSkills.push(skill);
    } else {
      updatedSelectedSkills.splice(skillIndex, 1);
    }

    setSelectedSkills(updatedSelectedSkills);
    onUpdate(updatedSelectedSkills);
  };

  return (
    <div className="popup-content">
      <h2>Vos compétences techniques ({selectedSkills.length})</h2>
      <div className="popup-body">
        {technicalSkills.map((skill: string, index: number) => {
          return (
            <span className="select-skill" key={index}>
              <div className="cntr">
                <input
                  type="checkbox"
                  id={`cbx-${index}`}
                  className="hidden-xs-up cbx-x"
                  defaultChecked={alreadySelectedTechnicalSkills.some((s: string) => s === skill)}
                  onChange={(): void => handleCheckChange(skill)}
                />
                <label htmlFor={`cbx-${index}`} className="cbx"></label>
              </div>
              <label htmlFor={`cbx-${index}`}>{skill}</label>
            </span>
          );
        })}
      </div>
      <div className="popup-actions">
        <button onClick={() => Swal.close()} className="secondary">
          Fermer
          <IconX />
        </button>
      </div>
    </div>
  );
};

export default TechnicalSkillsPopup;
