import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { askForConfirmation } from "../../shared/popups/askForConfirmation";
import { useUploadCv } from "../../providers/profile/hooks/useUploadCv";
import { useDeleteCv } from "../../providers/profile/hooks/useDeleteCv";
import { useCv } from "../../providers/profile/hooks/useCv";
import { IconScript } from "@tabler/icons-react";
import CardLoading from "../common/CardLoading";
import toast from "../../shared/popups/toast";

const CvUploader = (): ReactElement => {
  const [cvAlreadyExisting, setCvAlreadyExisting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const uploadCv = useUploadCv();
  const deleteCv = useDeleteCv();
  const cv = useCv();

  useEffect(() => {
    if (cv && cv.length) {
      setCvAlreadyExisting(true);
    }
  }, [cv]);

  const handleOpenSelection = (): void => {
    inputRef.current && inputRef.current.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    let cvUpdated: boolean = false;
    let actionConfirmed = !cvAlreadyExisting;

    if (!actionConfirmed) {
      actionConfirmed = await askForConfirmation("Vous avez déjà uploadé un CV sur my.socraft. En faisant ça, vous écraserez l'ancien. Êtes-vous sûr(e) de vouloir faire ça ?");
      cvUpdated = true;
    }

    if (actionConfirmed) {
      const file: File = e.target.files![0];

      try {
        setIsLoading(true);
        await uploadCv(file);
        setIsLoading(false);
        toast("success", `CV ${cvUpdated ? 'mis à jour' : 'uploadé'} !`);
      } catch (error: any) {
        toast("error", error);
      }
    }
  };

  const handleDeleteFile = async (): Promise<void> => {
    const actionConfirmed = await askForConfirmation("Êtes-vous sûr de vouloir supprimer votre CV de my.socraft ?");

    if (actionConfirmed) {
      try {
        setIsLoading(true);

        await deleteCv();
        setIsLoading(false);

        setCvAlreadyExisting(false);
        toast("success", "CV supprimé !");
      } catch (error: any) {
        throw new Error(error);
      }
    }
  };

  return (
    <>
      {isLoading && <CardLoading />}
      <div className="cv-uploader" data-aos="fade-up" data-aos-delay="300" onClick={handleOpenSelection}>
        <span className="label">
          <IconScript />
          Uploadez votre CV
        </span>
        <input onChange={(e) => handleFileChange(e)} type="file" ref={inputRef} />
      </div>
      <p className="status" data-aos="fade-up" data-aos-delay="350">
        {cvAlreadyExisting ? (
          <span>
            Votre CV est déjà sur my.socraft.
            <span className="actions">
              <a href={cv} target="_blank" rel="noreferrer">visualiser</a>
              <span onClick={handleDeleteFile} className="pointer yellow underline">supprimer</span>
            </span>
          </span>
        ) : (
          <span>Vous n'avez pas encore uploadé votre CV sur my.socraft.</span>
        )}
      </p>
    </>
  );
};

export default CvUploader;
