import { usePermissions } from "../../providers/profile/hooks/usePermissions";
import { filterAllowedTabs } from "../../shared/others/filterAllowedTabs";
import { displayedTabs, isDev, isQa } from "../../environnment";
import { ReactElement, useState, useEffect } from "react";
import { IconLogout2 } from "@tabler/icons-react";
import EnvironmentBadge from "./EnvironmentBadge";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo-dark.png";
import { auth } from "../../firebase.config";
import { Tab } from "../../types/tab";
import TabsGroups from "./TabsGroup";
import Skeleton from "./Skeleton";
import TabIcon from "./TabIcon";

const Sidebar = (): ReactElement => {
  const [activeTab, setActiveTab] = useState(window.location.pathname);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const permissions = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    const tab: Tab = displayedTabs.find((tab: Tab) => {
      return tab.path === window.location.pathname
    })!;
    document.title = `my.socraft - ${tab.name}`;

    setActiveTab(tab.path);
    navigate(tab.path);
  }, [navigate]);

  useEffect(() => {
    if (permissions) {
      setTabs(filterAllowedTabs(permissions));
    }
  }, [permissions]);

  const handleTabClick = (tab: Tab): void => {
    document.title = `my.socraft - ${tab.name}`;

    setActiveTab(tab.path);
    setIsOpen(false);

    navigate(tab.path);
  };

  const handleLogout = async (): Promise<void> => {
    await auth.signOut();
  };

  return (
    <>
      <label className="hamburger">
        <input
          type="checkbox"
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
        />
        <svg viewBox="0 0 32 32">
          <path
            className="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path
            className="line"
            d="M7 16 27 16"
          ></path>
        </svg>
      </label>
      <aside className={`${isOpen ? 'sidebar open' : 'sidebar'}`}>
        <header>
          <img
            onClick={() => window.location.href = ""}
            className="logo medium"
            alt="my.socraft"
            src={logo}
          />
        </header>
        <nav>
          {permissions ? tabs.map((tab: Tab, key: any) => {
            return (
              <button
                key={key}
                className={`navigation-tab ${activeTab === tab.path && 'current'}`}
                onClick={() => handleTabClick(tab)}>
                <TabIcon name={tab.name} />
                {tab.name}
              </button>
            );
          }) : displayedTabs.map((tab: Tab) => {
            return (
              <Skeleton
                height="52px"
                width="200px"
                key={tab.name}
              />
            );
          })}
          <TabsGroups title="Outils">
            <button
              className="navigation-tab"
              onClick={() => window.open("https://socraft.slack.com/")}>
              Slack
            </button>
            <button
              className="navigation-tab"
              onClick={() => window.open("https://socraft.ch/blog")}>
              Blog
            </button>
          </TabsGroups>
          <button onClick={handleLogout} className="navigation-tab">
            <IconLogout2 size={30} />
            Déconnexion
          </button>
          {(isDev || isQa) && <EnvironmentBadge />}
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
