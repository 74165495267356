import { collection, doc, DocumentData, getDocs, QueryDocumentSnapshot, setDoc } from "firebase/firestore";
import { formatHubspotContact } from "../../shared/hubspot/formatHubspotContact";
import { formatToBirthday } from "../../shared/directory/formatToBirthday";
import { createContext, ReactElement, useEffect, useState } from "react";
import qaProfiles from "../../static/directory/qaProfiles.json";
import { DirectoryProviderProps } from "../../types/directory";
import { db, functions, storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
import links from "../../static/others/links.json";
import { httpsCallable } from "firebase/functions";
import { isDev, isQa } from "../../environnment";
import { Profile } from "../../types/profile";
import sha256 from "sha256";

export const DirectoryContext = createContext(undefined);

export const DirectoryProvider = ({ children }: DirectoryProviderProps): ReactElement => {
  const [crafters, setCrafters] = useState<Profile[] | null>(null);
  const [birthdays, setBirthdays] = useState<string[]>([]);

  useEffect(() => {
    const initCrafters = async (): Promise<void> => {
      const fetchHubspotContact = httpsCallable(functions, "fetchHubspotContact");
      const profilesCollection = collection(db, "profiles");

      try {
        const craftersList: Profile[] = [];
        let profiles = await getDocs(profilesCollection);

        if ((isQa || isDev) && profiles.docs.length < 2) {
          qaProfiles.forEach(async (email: string) => {
            const profileDocumentReference = doc(db, "profiles", email);
            const hubspotContact = await fetchHubspotContact(email);

            let formattedProfile: Profile = formatHubspotContact(hubspotContact.data);

            const emailHash = sha256(formattedProfile.email);
            const defaultImageURL = links.defaultAvatar;

            formattedProfile.profilePicture = `https://www.gravatar.com/avatar/${emailHash}?s=200&d=${defaultImageURL}`;

            await setDoc(profileDocumentReference, formattedProfile);
          });
        }

        profiles = await getDocs(profilesCollection);

        profiles.forEach((profileDocument: QueryDocumentSnapshot<DocumentData>) => {
          craftersList.push(profileDocument.data() as Profile);
        });

        setCrafters(craftersList);
      } catch (error: any) {
        throw new Error(error);
      }
    };

    const initBirthdays = async (): Promise<void> => {
      const directoryCollection = collection(db, "profiles");

      try {
        const directoryDocumentsSnapshots = await getDocs(directoryCollection);
        const birthdaysList: string[] = [];

        directoryDocumentsSnapshots.forEach((document: QueryDocumentSnapshot<DocumentData>) => {
          const crafter: Profile = document.data() as Profile;

          const crafterBirhtday = formatToBirthday(crafter?.birth_date ?? "");
          const today = formatToBirthday(new Date());

          if (today.day === crafterBirhtday.day && today.month === crafterBirhtday.month) {
            birthdaysList.push(`${crafter.firstname} ${crafter.lastname}`)
          }
        });

        setBirthdays(birthdaysList);
      } catch (error: any) {
        throw new Error(error);
      }
    };

    void initCrafters();
    void initBirthdays();
  }, []);

  const getCrafterCv = async (crafterEmail: string): Promise<string | undefined> => {
    const cvFileReference = ref(storage, `CV/${crafterEmail}`);

    try {
      return await getDownloadURL(cvFileReference);
    } catch (error: any) {
      console.warn(`${crafterEmail}'s CV not found. The 404 error above this message is therefore not important`);
    }
  };

  const directoryContextValue: any = {
    crafters,
    birthdays,
    getCrafterCv,
  };

  return (
    <DirectoryContext.Provider value={directoryContextValue}>
      {children}
    </DirectoryContext.Provider>
  );
};
