import { PremiumValidity, Profile } from "../../types/profile";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase.config";
import { Timestamp } from "firebase/firestore";

export const getPremiumValidity = async (profile: Profile): Promise<PremiumValidity> => {
  const premiumUntil: Timestamp | undefined = profile.premiumUntil;
  const currentDateTime = new Date();

  if (premiumUntil && (new Date(premiumUntil.toMillis()) >= currentDateTime)) {
    return {
      isValid: true,
      validUntil: premiumUntil,
    };
  } else {
    const getSubscriptionValidUntil = httpsCallable(functions, "getSubscriptionValidUntil");
    const validUntil = (await getSubscriptionValidUntil({ email: profile.email })).data as any;

    return {
      isValid: new Date(validUntil) > new Date(),
      validUntil: Timestamp.fromDate(new Date(validUntil)),
      needToBeUpdatedInProfile: true,
    };
  }
};
