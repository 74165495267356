import { LayerProps } from "../types/layer";
import { ReactElement } from "react";

const FlexboxLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="flexbox">
      {children}
    </div>
  )
};

export default FlexboxLayer;
