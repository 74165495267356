import { ToastTypes } from "../../types/toast";
import Swal from "sweetalert2";

const toast = async (icon: keyof ToastTypes, text: string): Promise<void> => {
  await Swal.fire({
    toast: true,
    position: "bottom-right",
    iconColor: "white",
    color: "white",
    showConfirmButton: false,
    icon: icon as any,
    text,
    customClass: {
      popup: `toast ${icon}`,
    },
    timer: icon === "success" ? 3000 : 6000,
    timerProgressBar: true,
  });
};

export default toast;
