import { TechnicalSkillsSelectorProps } from "../../types/profile";
import TechnicalSkillsPopup from "./TechnicalSkillsPopup";
import withReactContent from "sweetalert2-react-content";
import { IconTagStarred } from "@tabler/icons-react";
import { ReactElement } from "react";
import Swal from "sweetalert2";

const TechnicalSkillsSelector = ({ alreadySelectedTechnicalSkills, onUpdate }: TechnicalSkillsSelectorProps): ReactElement => {
  const handleOpenPopup = (): void => {
    withReactContent(Swal).fire({
      html: (
        <TechnicalSkillsPopup
          alreadySelectedTechnicalSkills={alreadySelectedTechnicalSkills}
          onUpdate={handleSkillsChange}
        />
      ),
      showConfirmButton: false,
      customClass: 'popup',
    });
  };

  const handleSkillsChange = (updatedTechnicalSkillsList: string[]): void => {
    alreadySelectedTechnicalSkills = updatedTechnicalSkillsList;
    onUpdate(updatedTechnicalSkillsList);
  };

  return (
    <div className="input-group" onClick={handleOpenPopup}>
      <IconTagStarred />
      {alreadySelectedTechnicalSkills.length ? (
        <div className="skills">
          {alreadySelectedTechnicalSkills.map((skill: string, key: any) => {
            return (
              <span className="skill" key={key}>
                {skill}
              </span>
            );
          })}
        </div>
      ) : (
        <span className="no-content">Compétences techniques (veuillez sélectionner)</span>
      )}
    </div>
  );
};

export default TechnicalSkillsSelector;
