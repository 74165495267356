import { useCrafterCv } from "../../providers/directory/hooks/useCrafterCv";
import { CrafterElementProps } from "../../types/directory";
import { ReactElement, useEffect, useState } from "react";
import BlockLayer from "../../layers/BlockLayer";
import { IconX } from "@tabler/icons-react";
import Spinner from "../common/Spinner";
import Swal from "sweetalert2";

const CrafterPopup = ({ crafter, cvDownloadUrl }: CrafterElementProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cv, setCv] = useState<string | undefined>();
  const getCrafterCv = useCrafterCv();

  useEffect(() => {
    const initCrafterCv = async (): Promise<void> => {
      setIsLoading(true);
  
      try {
        const downloadUrl = cvDownloadUrl?.length
          ? cvDownloadUrl
          : await getCrafterCv(crafter.email);

        setCv(downloadUrl);
      } catch (error: any) {
        console.error("Error fetching Crafter CV:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    void initCrafterCv();
  }, [cvDownloadUrl, crafter, getCrafterCv]);

  return (
    <div className="popup-content">
      <header className="flex crafter-popup">
        <div className="side">
          <img
            className="crafter-avatar"
            alt="Crafter avatar"
            src={crafter.profilePicture}
          />
        </div>
        <div className="side flex column">
          <h2 className="crafter-fullname">
            {crafter.firstname} <span className="yellow">{crafter.lastname}</span>
          </h2>
          <span className="crafter-email">{crafter.email}</span>
          {crafter.job?.length && (
            <>
              <br />
              <span className="crafter-job">{crafter.job}</span>
            </>
          )}
          {isLoading && (
            <>
              <br />
              <Spinner />
            </>
          )}
          {cv && (
            <>
              <br />
              <a
                href={cv}
                target="_blank"
                rel="noreferrer"
                className="bold">
                CV
              </a>
            </>
          )}
        </div>
      </header>
      <BlockLayer>
        {(crafter.bio?.length ?? 0) > 0 && (
          <div className="card">
            <h2>Bio</h2>
            <p>{crafter.bio}</p>
          </div>
        )}
        {(crafter.technical_skills?.length || crafter.others_technical_skills?.length) && (
          <div className="card">
            {crafter.technical_skills?.length && (
              <>
                <h2>Compétences techniques</h2>
                <div className="skills">
                  {crafter.technical_skills?.split(";").map((skill: string, key: any) => {
                    return (
                      <span className="skill" key={key}>
                        {skill}
                      </span>
                    );
                  })}
                </div>
              </>
            )}
            {crafter.others_technical_skills?.length && (
              <>
                <br />
                <h3>Autres compétences techniques</h3>
                <p>{crafter.others_technical_skills}</p>
              </>
            )}
          </div>
        )}
      </BlockLayer>
      <div className="popup-actions">
        <button onClick={() => Swal.close()} className="secondary">
          Fermer
          <IconX />
        </button>
      </div>
    </div>
  );
};

export default CrafterPopup;
