import { Birthday } from "../../types/profile";

export const formatToBirthday = (date: Date | string): Birthday => {
  if (typeof date === "string") {
    return {
      month: date.split("-")[1],
      day: date.split("-")[2],
    };
  } else {
    const month = String(date.getMonth() + 1);
    const day = String(date.getDate());

    return {
      month: (Number(month) < 10 ? '0': '') + month,
      day: (Number(day) < 10 ? '0' : '') + day,
    }
  }
};
