export const formatDoorStatusCardClass = (status: number): string => {
  const okStatuses: number[] = [0, 2];
  const loadingStatuses: number[] = [1, 3];

  if (okStatuses.includes(status-1)) {
    return "ok";
  }

  if (loadingStatuses.includes(status-1)) {
    return "loading";
  }

  return "other";
};
