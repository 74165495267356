import { DirectoryProvider } from "./providers/directory/DirectoryProvider";
import { ProfileProvider } from "./providers/profile/ProfileProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DoorsProvider } from "./providers/doors/DoorsProvider";
import { useState, useEffect, ReactElement } from "react";
import { onAuthStateChanged } from "@firebase/auth";
import Sidebar from "./components/common/Sidebar";
import Authenticate from "./pages/Authenticate";
import Loader from "./components/common/Loader";
import Headquarter from "./pages/Headquarter";
import Directory from "./pages/Directory";
import { auth } from "./firebase.config";
import Calendar from "./pages/Calendar";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import "aos/dist/aos.css";
import aos from "aos";

const App = (): ReactElement => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      setUser(user);
      setTimeout(() => setLoading(false), 1000);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    aos.init({
      once: true,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Authenticate />;
  }

  return (
    <BrowserRouter>
      <ProfileProvider>
        <DoorsProvider>
          <DirectoryProvider>
            <Sidebar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/headquarter" element={<Headquarter />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/directory" element={<Directory />} />
              <Route path="/calendar" element={<Calendar />} />
            </Routes>
          </DirectoryProvider>
        </DoorsProvider>
      </ProfileProvider>
    </BrowserRouter>
  );
}

export default App;
