import { Profile } from "../../types/profile";
import toast from "../popups/toast";

export const wishBirthdays = (peopleWhoHaveTheirBirthday: string[], profile: Profile): void => {
  if (peopleWhoHaveTheirBirthday.length) {
    const fullname = `${profile?.firstname} ${profile?.lastname}`;

    if (peopleWhoHaveTheirBirthday.some((birthday: string) => birthday === fullname)) {
      toast("warning", "Joyeux anniversaire !");
    } else {
      let toastText: string;

      if (peopleWhoHaveTheirBirthday.length > 1) {
        const lastPersonToHaveTheirBirthday: string = peopleWhoHaveTheirBirthday.pop() ?? "";
        toastText = `${peopleWhoHaveTheirBirthday.join(", ")} et ${lastPersonToHaveTheirBirthday}`;
      } else {
        toastText = `${peopleWhoHaveTheirBirthday[0]}`
      }

      toast("warning", `C'est l'anniversaire de ${toastText} aujourd'hui !`);
    }
  }
};
