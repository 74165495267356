import { LayerProps } from "../types/layer";
import { ReactElement } from "react";

const PageLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="container">
      <section className="page">
        {children}
      </section>
    </div>
  )
};

export default PageLayer;
