import { LayerProps } from "../types/layer";
import { ReactElement } from "react";

const AuthLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="container">
      <section className="authentication">
        {children}
      </section>
    </div>
  )
};

export default AuthLayer;
