import { useBirthdays } from "../providers/directory/hooks/useBirthdays";
import { useProfile } from "../providers/profile/hooks/useProfile";
import { IconHome, IconBuildingEstate } from "@tabler/icons-react";
import { wishBirthdays } from "../shared/directory/wishBirthdays";
import FlexboxLayer from "../layers/FlexboxLayer";
import Loader from "../components/common/Loader";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/common/Card";
import Date from "../components/common/Date";
import PageLayer from "../layers/PageLayer";

const Home = (): ReactElement => {
  const birthdays = useBirthdays();
  const navigate = useNavigate();
  const profile = useProfile();

  useEffect(() => {
    wishBirthdays(birthdays, profile);
  }, [birthdays, profile]);

  if (!profile) {
    return <Loader />;
  }

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconHome size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Bonjour {profile?.firstname}</h1>
          <Date />
        </div>
      </header>
      <FlexboxLayer>
        <Card
          hasFooter
          footerContent={(
            <button
              onClick={() => navigate("/headquarter")}
              className="primary float-right">
              Accès au QG <IconBuildingEstate />
            </button>
          )}
          customClass="localsPreview"
        />
      </FlexboxLayer>
    </PageLayer>
  );
};

export default Home;
