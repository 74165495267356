import { DirectoryProvider } from "../../providers/directory/DirectoryProvider";
import { CrafterElementProps } from "../../types/directory";
import withReactContent from "sweetalert2-react-content";
import { IconPlus } from "@tabler/icons-react";
import GridLayer from "../../layers/GridLayer";
import CrafterPopup from "./CrafterPopup";
import { ReactElement } from "react";
import Swal from "sweetalert2";

const Crafter = ({ crafter, cvDownloadUrl, isCurrentProfile }: CrafterElementProps): ReactElement => {
  const handleShowMore = (): void => {
    withReactContent(Swal).fire({
      html: (
        <DirectoryProvider>
          <CrafterPopup
            crafter={crafter}
            cvDownloadUrl={cvDownloadUrl}
            isCurrentProfile={isCurrentProfile}
          />
        </DirectoryProvider>
      ),
      showConfirmButton: false,
      customClass: 'popup',
    });
  };

  return (
    <div className="crafter-card">
      <GridLayer>
        <img
          className="crafter-avatar"
          alt="Crafter avatar"
          loading="lazy"
          src={crafter.profilePicture}
        />
        <div className="crafter-infos">
          {crafter.email?.length && (
            <span className="crafter-info">
              {crafter.email}
            </span>
          )}
          {crafter.phone?.length && (
            <span className="crafter-info">
              {crafter.phone}
            </span>
          )}
        </div>
        <h2 className="crafter-fullname">
          {crafter.firstname} <span className="yellow">{crafter.lastname}</span>
        </h2>
        <div className="crafter-actions">
          <button onClick={handleShowMore} className="primary">
            Voir plus
            <IconPlus />
          </button>
        </div>
      </GridLayer>
    </div>
  );
};

export default Crafter;
