import { displayedTabs } from "../../environnment";
import { Permission } from "../../types/profile";
import { Tab } from "../../types/tab";

export const filterAllowedTabs = (permissions: Permission[], tabs: Tab[] = displayedTabs): Tab[] => {
  const tabsWithoutSpecificPermissions: Tab[] = tabs.filter((tab) => !tab.neededPermission);

  if (!permissions.length) {
    return tabsWithoutSpecificPermissions;
  }

  return permissions.reduce((acc: Tab[], permission) => {
    const tabsThatNeedsThatPermissions = tabs.filter((tab) => {
      return tab.neededPermission === permission.permission_name
    });
    
    return [...acc, ...tabsThatNeedsThatPermissions];
  }, tabsWithoutSpecificPermissions).sort((a, b) => a.index - b.index);
};
