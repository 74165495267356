import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase.config";

export const deleteFile = async (filePath: string, fileName: string): Promise<void> => {
  const fileReference = ref(storage, `${filePath}/${fileName}`);

  try {
    await deleteObject(fileReference);
  } catch (error: any) {
    throw new Error(error);
  }
};
