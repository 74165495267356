import { ReactElement } from "react";
import Spinner from "./Spinner";

const CardLoading = (): ReactElement => {
  return (
    <div className="card-loading">
      <div>
        <Spinner />
        <br /><br /><br />
        <span>Cette action peut prendre quelques instants...</span>
      </div>
    </div>
  );
};

export default CardLoading;
