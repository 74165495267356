import { updateProfileObjectProperty } from "../shared/profile/updateProfileObjectProperty";
import { IconUser, IconActivity, IconTrash, IconDeviceFloppy } from "@tabler/icons-react";
import TechnicalSkillsSelector from "../components/profile/TechnicalSkillsSelector";
import { useEditProfile } from "../providers/profile/hooks/useEditProfile";
import { informationsInputs } from "../static/profile/informationsInputs";
import { Profile as ProfileProps, ProfileInput } from "../types/profile";
import { useProfile } from "../providers/profile/hooks/useProfile";
import { activityInputs } from "../static/profile/activityInputs";
import ProfileIcon from "../components/profile/ProfileIcon";
import CardLoading from "../components/common/CardLoading";
import { ReactElement, useEffect, useState } from "react";
import CvUploader from "../components/profile/CvUploader";
import Toggleable from "../components/common/Toggleable";
import { IconBuildingEstate } from "@tabler/icons-react";
import { IconUserStar } from "@tabler/icons-react";
import links from "../static/others/links.json";
import BlockLayer from "../layers/BlockLayer";
import Date from "../components/common/Date";
import PageLayer from "../layers/PageLayer";
import toast from "../shared/popups/toast";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const Profile = (): ReactElement => {
  const [profileProfessionalStatus, setProfileProfessionalStatus] = useState<string>();
  const [profileTechnicalSkills, setProfileTechnicalSkills] = useState<string[]>([]);
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState<boolean>(false);
  const [fieldsWithChanges, setFieldsWithChanges] = useState<string[]>([]);
  const [openToggleable, setOpenToggleable] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm();
  const editProfile = useEditProfile();
  const profile = useProfile();

  useEffect(() => {
    setProfileProfessionalStatus(profile?.professional_status);
    setProfileTechnicalSkills(profile?.technical_skills?.length ? profile?.technical_skills.split(";") : []);
  }, [profile]);

  const handleEditAvatar = async (): Promise<void> => {
    const result = await Swal.fire({
      icon: "info",
      confirmButtonColor: "#fbbb10",
      confirmButtonText: "<span style='color: #282828;'>Modifier</span>",
      showCancelButton: true,
      cancelButtonColor: "#282828",
      cancelButtonText: "Fermer",
      focusConfirm: false,
      title: "Modification de la photo de profil",
      text: "Pour mettre à jour votre photo de profil, vous devez créer un compte Gravatar avec l'adresse e-mail que vous utilisez sur my.socraft. Ensuite, personnalisez votre photo de profil sur Gravatar.",
      customClass: "popup",
    });

    if (result.isConfirmed) {
      window.open(links.editGravatarPicture);
    }
  };

  const handleTechnicalSkillsChange = (updatedTechnicalSkillsList: string[]): void => {
    setProfileTechnicalSkills(updatedTechnicalSkillsList);
    setChangesHaveBeenMade(true);
  };

  const handleSaveChanges = async (formValues: ProfileProps): Promise<void> => {
    formValues.technical_skills = profileTechnicalSkills?.length ? profileTechnicalSkills.join(";") : "";

    Object.keys(formValues).forEach((key): void => {
      if (
        !fieldsWithChanges.some((field: string) => field === key) &&
        !String(formValues[key as keyof ProfileProps]).length &&
        key !== "technical_skills"
      ) {
        delete formValues[key as keyof ProfileProps];
      }
    });

    let updatedProfile: ProfileProps = { ...profile };

    Object.entries(formValues).forEach(([key, value]): void => {
      updatedProfile = updateProfileObjectProperty(key as keyof ProfileProps, value, updatedProfile);
    });

    if (profileProfessionalStatus !== "none") {
      updatedProfile.professional_status = profileProfessionalStatus;
    }

    try {
      setIsLoading(true);

      setChangesHaveBeenMade(false);
      await editProfile(updatedProfile);

      setIsLoading(false);
      toast("success", "Modifications enregistrées !");
    } catch (error: any) {
      toast("error", error);
    }
  };

  const handleInputChange = (changedInput: string): void => {
    setChangesHaveBeenMade(true);

    const updatedChangedFieldsList = [...fieldsWithChanges];
    updatedChangedFieldsList.push(changedInput);

    setFieldsWithChanges(updatedChangedFieldsList);
  };

  const handleCancelChanges = (): void => {
    const newTechnicalSkills = profile?.technical_skills?.length ? profile?.technical_skills.split(";") : [];
    setProfileTechnicalSkills(newTechnicalSkills);

    setProfileProfessionalStatus(profile?.professional_status);
    setChangesHaveBeenMade(false);
    reset();
  };

  return (
    <PageLayer>
      <header>
        <div className="icon" onClick={handleEditAvatar}>
          <img
            className="avatar"
            alt="mysocraft avatar"
            src={profile?.profilePicture}
          />
        </div>
        <div className="text">
          <h1 className="page-title">Votre profil</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <form onSubmit={handleSubmit(handleSaveChanges)}>
          <Toggleable
            id="informationsToggleable"
            setWichIsOpen={setOpenToggleable}
            openToggleableId={openToggleable ?? ""}
            label="Vos informations"
            icon={<IconUser />}>
            <>
              {isLoading && <CardLoading />}
              {informationsInputs.map((input: ProfileInput, key: any) => {
                return (
                  <div className="input-group" key={key}>
                    <ProfileIcon property={input.property} />
                    {!input.isTextarea ? (
                      <input
                        type={input.type}
                        placeholder={input.placeholder}
                        defaultValue={profile ? profile[input.property] as string : ""}
                        readOnly={input.readOnly}
                        {...register(input.property)}
                        onChange={() => handleInputChange(input.property)}
                      />
                    ) : (
                      <textarea
                        placeholder={input.placeholder}
                        defaultValue={profile ? profile[input.property] as string : ""}
                        readOnly={input.readOnly}
                        {...register(input.property)}
                        onChange={() => handleInputChange(input.property)}
                      ></textarea>
                    )}
                  </div>
                );
              })}
            </>
          </Toggleable>
          <Toggleable
            id="activityToggleable"
            setWichIsOpen={setOpenToggleable}
            openToggleableId={openToggleable ?? ""}
            label="Votre activité"
            icon={<IconActivity />}
            fadeDelay={100}>
            <>
              {isLoading && <CardLoading />}
              <TechnicalSkillsSelector
                alreadySelectedTechnicalSkills={profileTechnicalSkills ?? []}
                onUpdate={handleTechnicalSkillsChange}
              />
              {activityInputs.map((input: ProfileInput, key: any) => {
                return (
                  <div className="input-group" key={key}>
                    <ProfileIcon property={input.property} />
                    <input
                      type={input.type}
                      placeholder={input.placeholder}
                      defaultValue={profile ? profile[input.property] as string : ""}
                      readOnly={input.readOnly}
                      {...register(input.property)}
                      onChange={() => setChangesHaveBeenMade(true)}
                    />
                  </div>
                );
              })}
              <div className="input-group">
                <IconUserStar />
                <select
                  value={profileProfessionalStatus?.length ? profileProfessionalStatus : "none"}
                  onChange={(e) => {
                    setProfileProfessionalStatus(e.target.value);
                    setChangesHaveBeenMade(true);
                  }}>
                  <option value="none" disabled>
                    Statut Professionnel (veuillez sélectionner)
                  </option>
                  <option value="Indépendant⸱e">
                    Indépendant
                  </option>
                  <option value="Salarié⸱e">
                    Salarié⸱e
                  </option>
                </select>
              </div>
              {profileProfessionalStatus === "Salarié⸱e" && (
                <div className="input-group">
                  <IconBuildingEstate />
                  <input
                    type="text"
                    placeholder="Nom de l'entreprise"
                    defaultValue={profile?.company}
                    {...register("company")}
                    onChange={() => setChangesHaveBeenMade(true)}
                  />
                </div>
              )}
            </>
          </Toggleable>
          {changesHaveBeenMade && (
            <div className="actions floating">
              <button
                type="reset"
                className="secondary"
                onClick={handleCancelChanges}>
                Supprimer les modifications
                <IconTrash />
              </button>
              <button type="submit" className="primary">
                Enregistrer les modification
                <IconDeviceFloppy />
              </button>
            </div>
          )}
        </form>
        <CvUploader />
      </BlockLayer>
    </PageLayer>
  );
};

export default Profile;
