import { ToggleableProps } from "../../types/toggleable";
import { IconPlus } from "@tabler/icons-react";
import { ReactElement } from "react";

const Toggleable = ({ id, label, icon, children, openToggleableId, fadeDelay, setWichIsOpen }: ToggleableProps): ReactElement => {
  const handleToggle = (): void => {
    setWichIsOpen(openToggleableId === id ? "" : id);
  };

  return (
    <div className={`toggleable ${openToggleableId === id && 'opened'}`}>
      <div 
        className="label"
        onClick={handleToggle}
        data-aos="fade-up"
        data-aos-delay={fadeDelay}>
        {icon}
        {label}
        <IconPlus className="icon" size={30} />
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  );
};

export default Toggleable;
