import { DoorsContext } from "../DoorsProvider";
import { Door } from "../../../types/doors";
import { useContext } from "react";

export const useDoors = (): Door[] => {
  const doorsContext = useContext<any>(DoorsContext);

  if (!doorsContext) {
    throw new Error("useDoors must be used within a DoorsProvider");
  };

  return doorsContext.doors;
};
