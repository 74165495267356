import { ProfileContext } from "../ProfileProvider";
import { Profile } from "../../../types/profile";
import { useContext } from "react";

export const useEditProfile = (): ((updatedProfile: Profile) => Promise<void>) => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useEditProfile must be used within a ProfileProvider");
  }

  return profileContext.editProfile;
};
