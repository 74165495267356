const statuses: Statuses = require("../../static/doors/doorsStatuses.json");

interface Statuses {
  indexes: {
    [key: string]: string;
  };
}

export const getIndexOfStatus = (status: string): number => {
  const foundEntry = Object.entries(statuses.indexes).find(
    ([key, value]) => value === status
  );

  if (foundEntry) {
    const [key] = foundEntry;
    return parseInt(key);
  }

  return -1;
};
