import { ProfileInput } from "../../types/profile";

export const activityInputs: ProfileInput[] = [
  {
    type: "text",
    placeholder: "Autres compétences techniques",
    property: "others_technical_skills",
  },
  {
    type: "text",
    placeholder: "Fonction actuelle",
    property: "job",
  },
  {
    type: "text",
    placeholder: "Taux horaire (sur une base de travail de 8h / jour)",
    property: "rate",
  },
];
