import { IconBuildingEstate, IconMapPin, IconDoorEnter, IconLockOpen, IconLock, IconRouter, IconPrinter, IconAlertTriangleFilled } from "@tabler/icons-react";
import { formatDoorStatusCardClass } from "../shared/doors/formatDoorStatusCardClass";
import { useControlDoors } from "../providers/doors/hooks/useControlDoors";
import { askForConfirmation } from "../shared/popups/askForConfirmation";
import { formatDoorStatus } from "../shared/doors/formatDoorStatus";
import { formatDoorAction } from "../shared/doors/formatDoorAction";
import { useDoors } from "../providers/doors/hooks/useDoors";
import CardLoading from "../components/common/CardLoading";
import Toggleable from "../components/common/Toggleable";
import printer from "../images/custom/printer.jpg";
import links from "../static/others/links.json";
import { ReactElement, useState } from "react";
import BlockLayer from "../layers/BlockLayer";
import Date from "../components/common/Date";
import PageLayer from "../layers/PageLayer";
import toast from "../shared/popups/toast";
import { Door } from "../types/doors";
import Swal from "sweetalert2";

const Headquarter = (): ReactElement => {
  const [openToggleable, setOpenToggleable] = useState<string | undefined>();
  const [doorsLoading, setDoorsLoading] = useState<boolean>(false);

  const controlDoors = useControlDoors();
  const doors = useDoors();

  const handleDoorsAction = async (action: string): Promise<void> => {
    try {
      const isConfirmed: boolean = await askForConfirmation("Êtes vous sûr(e) de vouloir faire ça ?");

      if (isConfirmed) {
        setDoorsLoading(true);

        const actionSucceeded: boolean = await controlDoors(action);
        setDoorsLoading(false);

        if (!actionSucceeded) {
          Swal.fire({
            icon: "error",
            title: "Oops ! Une erreur est survenue",
            text: "Une des portes ne répond pas. Veuillez vérifier l'état de la batterie puis réessayer",
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonColor: "#fbbb10",
            denyButtonText: "<span style='color: #282828;'>Fermer</span>",
            focusDeny: false,
            customClass: "popup",
          });
        } else {
          const formattedAction: string = formatDoorAction(action as "lock" | "unlock");
          toast("success", `Les portes ont été ${formattedAction}ées !`);
        }
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconBuildingEstate size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Le QG</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <Toggleable
          id="place"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Le lieu"
          icon={<IconMapPin size={30} />}>
          <div className="flex align-center space-between place-header">
            <h2>Où se trouvent nos locaux ?</h2>
            <span>
              Code d'entrée de l'immeuble <span className="value">2385</span>
            </span>
          </div>
          <iframe
            src={links.headquarterPosition}
            className="headquarter-position"
            title="headquarter position"
          ></iframe>
        </Toggleable>
        <Toggleable
          id="access"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Accès"
          icon={<IconDoorEnter size={30} />}
          fadeDelay={100}>
          {doorsLoading && <CardLoading />}
          <div className="doors">
            <div className="buttons">
              <button
                className="primary"
                onClick={() => handleDoorsAction("unlock")}>
                Déverrouiller les portes
                <IconLockOpen />
              </button>
              <button
                className="primary"
                onClick={() => handleDoorsAction("lock")}>
                Vérrouiller les portes
                <IconLock />
              </button>
            </div>
            <div className="statuses">
              {doors && doors.length ? doors.map((door: Door, key: any) => {
                const doorState: string = formatDoorStatus(door.state.state);
                const statusCardClass: string = formatDoorStatusCardClass(door.state.state);

                return (
                  <div className={`card status ${statusCardClass}`} key={key}>
                    {door.name} : {doorState || "statut inconnu"}
                  </div>
                );
              }) : (
                <div className="card status other flex align-center" style={{gap: "10px"}}>
                  <IconAlertTriangleFilled />
                  La récupération du statut des portes a rencontré un problème
                </div>
              )}
            </div>
          </div>
        </Toggleable>
        <Toggleable
          id="wifi"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Wi-Fi"
          icon={<IconRouter />}
          fadeDelay={200}>
          <>
            <h2>Se connecter à notre réseau</h2>
            <br />
            <span className="wifi-prop">
              Nom du réseau
              <span className="value">{'<socraft />'}</span>
            </span>
            <span className="wifi-prop">
              Mot de passe
              <span className="value">crafter-4-ever</span>
            </span>
          </>
        </Toggleable>
        <Toggleable
          id="printer"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? ""}
          label="Imprimante"
          icon={<IconPrinter />}
          fadeDelay={300}>
          <>
            <h2>Utiliser l'imprimante</h2>
            <br />
            <div className="flex align-center printer" style={{ gap: "3em" }}>
              <img style={{ borderRadius: "15px 25px" }} height="300" src={printer} alt="Imprimante socraft" />
              <div>
                <span>Nom de l'imprimante <span className="value">HP Color LaserJet MFP M283fdw (FCBB78)</span></span>
                <br /><br />
                <strong style={{ opacity: "0.5" }}>Imprimante en libre service, accessible depuis le réseau WiFi.</strong>
              </div>
            </div>
          </>
        </Toggleable>
      </BlockLayer>
    </PageLayer>
  );
};

export default Headquarter;
