import { LayerProps } from "../types/layer";
import { ReactElement } from "react";

const GridLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="grid">
      {children}
    </div>
  )
};

export default GridLayer;
