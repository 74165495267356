import { formatDate } from "../others/formatDate";
import { Profile } from "../../types/profile";
import { isDev, isQa } from "../../environnment";

// This function exists because hubspot properties do not have the same names in the prod directory as in the QA directory.

export const formatHubspotContact = (hubspotResponse: any): Profile => {
  const fieldConfig = !isDev && !isQa
    ? require("../../static/profile/hubspotProdFields.json")
    : require("../../static/profile/hubspotQaFields.json");

  let profile: any = {};
 
  for (const rawFieldName in fieldConfig) {
    const hubspotFieldName = fieldConfig[rawFieldName];
    if (hubspotResponse.result[hubspotFieldName]) {
      if (rawFieldName === "birth_date") {
        profile[rawFieldName] = formatDate(
          Number(hubspotResponse.result[hubspotFieldName].value)
        );
      } else {
        profile[rawFieldName] = hubspotResponse.result[hubspotFieldName].value;
      }
    }
  }

  return profile;
}
