import { IconCalendar } from "@tabler/icons-react";
import links from "../static/others/links.json";
import { ReactElement, useState } from "react";
import BlockLayer from "../layers/BlockLayer";
import Date from "../components/common/Date";
import PageLayer from "../layers/PageLayer";
import Loader from "../components/common/Loader";

const Calendar = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <PageLayer>
      {isLoading && <Loader />}
      <header>
        <div className="icon">
          <IconCalendar size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Calendrier</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <iframe 
          src={links.calendarSource}
          className="calendar-iframe"
          title="Calendar"
          onLoad={() => setIsLoading(false)}
        ></iframe>
      </BlockLayer>
    </PageLayer>
  );
};

export default Calendar;
