import {
  IconAt,
  IconBrandLinkedin,
  IconBuildingEstate,
  IconCake,
  IconClockDollar,
  IconListDetails,
  IconMapPins,
  IconPhone,
  IconUser,
  IconUserScan,
  IconWriting,
} from "@tabler/icons-react";
import { ReactElement } from "react";
import { Profile } from "../../types/profile";

const ProfileIcon = ({ property }: any): ReactElement => {
  switch (property as keyof Profile) {
    case "email":
      return <IconAt />;
    case "birth_date":
      return <IconCake />;
    case "phone":
      return <IconPhone />;
    case "postal_address":
      return <IconMapPins />;
    case "linkedin":
      return <IconBrandLinkedin />;
    case "others_technical_skills":
      return <IconListDetails />;
    case "job":
      return <IconUserScan />;
    case "company":
      return <IconBuildingEstate />;
    case "rate":
      return <IconClockDollar />;
    case "bio":
      return <IconWriting />
    default:
      return <IconUser />;
  }
};

export default ProfileIcon;
