import { IconChevronDown } from "@tabler/icons-react";
import { ReactElement, useState } from "react";

const TabsGroups = ({ children, title }: any): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div onClick={() => setIsOpen(!isOpen)} className={`tabs-label ${isOpen && 'open'}`}>
        <IconChevronDown className="icon" size={30} />
        {title}
      </div>
      {isOpen && (
        <div className="tabs-content">
          {children}
        </div>
      )}
    </>
  );
};

export default TabsGroups;
